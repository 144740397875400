jQuery(document).ready(function($) {

    
    // Toggle form happen today
    $("#happen-today .toggle-form").click(function(){//executer cette fonc
        $("#happen-today form").slideToggle();
        return false;
    })

    // More/Less link
    $("#topics-action a").click(
        function(e){
            e.preventDefault();
            $('#the-topics').toggleClass('display-all');
            $('#topics-action li').toggleClass('hidden');
        }
    );
    $("#authors-action a").click(
        function(e){
            e.preventDefault();
            $('#the-authors').toggleClass('display-all');
            $('#authors-action li').toggleClass('hidden');
        }
    );


    // Caroufdsel
    $('.caroufdsel').carouFredSel({
        auto: false,
        responsive: true,
        prev: function (){
            return $(this).parents().eq(1).find(".prev");
        },
        next: function (){
            return $(this).parents().eq(1).find(".next");
        },
        scroll: 1,
        width: '100%',
        items: {
            width: 200,
        //  height: '30%',  //  optionally resize item-height
            visible: {
                min: 1,
                max: 6
            }
        }
    });


    // Open search form
    $(".open-search").click(function(){
        $("#search-form").slideDown();
        return false;
    });
    // Close search form
    $("#close-search").click(function(){
        $("#search-form").slideUp();
        return false;
    });

    // Masonry
    window.onload = function(){
        $('.masonry-container').masonry({
            columnWidth: '.item',
            itemSelector: '.item'
        }); 
    }  

});